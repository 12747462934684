import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.parse-int.js";
/**
 * detect IE
 * and add a specific class to the body
 * function modified from this: http://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery
 */
(function () {
  var ua = window.navigator.userAgent;
  var b = "";
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    b = "ie msie ie" + parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    b = "ie trident ie" + parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  var isOldEdgeOrIE = !('reversed' in document.createElement('ol'));

  // other browser
  if (b === "" && isOldEdgeOrIE) {
    b = 'edge-old';
  }
  if (b !== '') {
    document.body.className += " " + b;
  }
})();